import React, { useRef } from "react"
import style from "./index.module.less"
import classNames from "classnames"

const TouMingHeiBtn = props => {
  const { children, disabled, onClick, styles } = props
  return (
    <button className={classNames(style.btn, style.btn_tmh)} style={{ ...styles }} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

const TouMingBaiBtn = props => {
  const { children, disabled, onClick, styles } = props
  return (
    <button className={classNames(style.btn, style.btn_tmb)} style={{ ...styles }} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

const ShiXinHeiBtn = props => {
  const { children, disabled, onClick, styles } = props
  return (
    <button className={classNames(style.btn, style.btn_sxh)} style={{ ...styles }} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

const ShiXinBaiBtn = props => {
  const { children, disabled, onClick, styles } = props

  return (
    <button className={classNames(style.btn, style.btn_sxb)} style={{ ...styles }} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

const BlackDotBtn = props => {
  const { children, disabled, onClick, styles } = props

  return (
    <div className={style.bDotBtn} style={{ ...styles }} onClick={onClick} disabled={disabled} >
      {children}
      <span className={style.dot} ></span>
    </div>
  )
}

const TouMingBaiBtn_updown = props => {
  const { children, disabled, onClick, styles } = props

  return (
    <button className={style.tmbUD} style={{ ...styles }} onClick={onClick} disabled={disabled}>
      <div className={style.tmbUDBox}>
        {children}
        {/* {children} */}
      </div>
      {/* <div className={style.tmbUD_placeHolder}>
        {children}
      </div> */}
    </button>
  )
}

const ShiXinBaiBtn_updown = props => {
  const { children, disabled, onClick, styles, className } = props

  return (
    <button className={classNames(style.sxbUD, className)} style={{ ...styles }} onClick={onClick} disabled={disabled}>
      <div className={style.sxbUDBox}>
        {children}
      </div>
      {/* <div className={style.sxbUD_placeHolder}>
        {children}
      </div> */}
    </button>
  )
}
const ShiXinBaiBtn_up = props => {
  const { children, disabled, onClick, styles, className } = props
  return (
    <button className={classNames(style.sxbUP, className)} style={{ ...styles }} onClick={onClick} disabled={disabled}>
      <div className={style.sxbUPBox}>
        {children}
      </div>
    </button>
  )
}
const ShiXinHeiBtn_updown = props => {
  const { children, disabled, onClick, styles } = props

  return (
    <button className={style.sxhUD} style={{ ...styles }} onClick={onClick} disabled={disabled} >
      <div className={style.sxhUDBox}>
        {children}
        {/* {children} */}
      </div>
      {/* <div className={style.sxhUD_placeHolder}>
        {children}
      </div> */}
    </button>
  )
}

const ShiXinHeiBtn_scale = props => {
  const { children, disabled, onClick, styles } = props
  return (
    <button className={style.sxhScale} style={{ ...styles }} onClick={onClick} disabled={disabled} >
      <div className={style.sxhScaleBox}>
        {children}
      </div>
    </button>
  )
}
const TouMingHeiBtn_scale = props => {
  const { children, disabled, onClick, styles } = props
  return (
    <button className={style.tmhScale} style={{ ...styles }} onClick={onClick} disabled={disabled} >
      <div className={style.tmhScaleBox}>
        {children}
      </div>
    </button>
  )
}

export { TouMingHeiBtn, TouMingBaiBtn, ShiXinHeiBtn, ShiXinBaiBtn, BlackDotBtn, TouMingBaiBtn_updown, ShiXinBaiBtn_updown, ShiXinBaiBtn_up, ShiXinHeiBtn_updown, ShiXinHeiBtn_scale, TouMingHeiBtn_scale }
