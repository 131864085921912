const upgradeBg = `${process.env.resourceUrl}images/support/update.webp`
const headerBg = `${process.env.resourceUrl}images/experience/headerBg.webp`
const lightBanner = `${process.env.resourceUrl}images/light/home-light.jpg`
const desktopPic = `${process.env.resourceUrl}images/experience/what-is-virtual-desktop.jpg`
const s1 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S1.jpg`
const s2 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S2.jpg`
const s3 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S3.jpg`
const s4 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S4.jpg`
const s5 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S5.jpg`
const s6 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S6.jpg`
const s7 = `${process.env.resourceUrl}images/experience/spatialMirrorView/S7.jpg`
const m1 = `${process.env.resourceUrl}images/experience/mirrorView/S1.jpg`
const m8 = `${process.env.resourceUrl}images/experience/mirrorView/S8.jpg`
const m2 = `${process.env.resourceUrl}images/experience/mirrorView/S2.jpg`
const m3 = `${process.env.resourceUrl}images/experience/mirrorView/S3.jpg`
const m4 = `${process.env.resourceUrl}images/experience/mirrorView/S4.jpg`
const m5 = `${process.env.resourceUrl}images/experience/mirrorView/S5.jpg`
const m6 = `${process.env.resourceUrl}images/experience/mirrorView/S6.jpg`
const m7 = `${process.env.resourceUrl}images/experience/mirrorView/S7.jpg`
const v1 = `${process.env.resourceUrl}images/experience/virtualDesktop/S1.jpg`
const v2 = `${process.env.resourceUrl}images/experience/virtualDesktop/S2.jpg`
const v3 = `${process.env.resourceUrl}images/experience/virtualDesktop/S3.jpg`
const v4 = `${process.env.resourceUrl}images/experience/virtualDesktop/S4.jpg`

const nebulaIcon = `${process.env.resourceUrl}images/logo/nebula_app_logo.png`
const threeTypeAnchor = `${process.env.resourceUrl}images/experience/anchor.gif`
const threeTypeSmooth = `${process.env.resourceUrl}images/experience/smoothFollow.gif`
const nosmooth = `${process.env.resourceUrl}images/experience/off.gif`
const smooth = `${process.env.resourceUrl}images/experience/on.gif`
const threeTypeSide = `${process.env.resourceUrl}images/experience/sideView.gif`
const odofGif = `${process.env.resourceUrl}images/experience/0dof_1.gif`
const arspaceGlass = `${process.env.resourceUrl}images/experience/arspace-glass.webp`
const aircastSetup1 = `${process.env.resourceUrl}images/experience/airCastUSB.jpg`
const aircastSetup2 = `${process.env.resourceUrl}images/experience/airCastAda.jpg`

import nebulaMacIcon from './nebulaMac.png'
import compatibilityada from './compatibility-ada.svg'
import compatibilityandroid from './compatibility-android.svg'
import compatibilityav from './compatibility-av.svg'
import compatibilitydp from './compatibility-dp.svg'
import compatibilityhdmi from './compatibility-hdmi.svg'
import compatibilitymac from './compatibility-mac.svg'
import checkMac from './check-mac.svg'
import windowLogo from './windowLogo.svg'
import macLogo from './macLogo.svg'
import detectFalse from './detectFalse.svg'
import detectTrue from './detectTrue.svg'
import googlePlay from './googlePlay.png'
import windowIcon from './window.svg'
import macIcon from './mac.svg'
import youtubeIcon from './youtube.png'
import teleportIcon from './teleport.png'


const projector =`${process.env.resourceUrl}images/experience/projector.jpg`
const vr = `${process.env.resourceUrl}images/experience/vr.jpg`
const xrealair =`${process.env.resourceUrl}images/experience/xrealair.jpg`
const drm_1 = `${process.env.resourceUrl}images/experience/drm_1.png`
const drm_2 = `${process.env.resourceUrl}images/experience/drm_2.png`
const drm_3 = `${process.env.resourceUrl}images/experience/drm_3.png`
const drm_4 = `${process.env.resourceUrl}images/experience/drm_4.png`
const drm_5 = `${process.env.resourceUrl}images/experience/drm_5.png`
const drm_6 = `${process.env.resourceUrl}images/experience/drm_6.png`
const drm_7 = `${process.env.resourceUrl}images/experience/drm_7.png`
const drm_8 = `${process.env.resourceUrl}images/experience/drm_8.png`

const arspaceMedia1 = `${process.env.resourceUrl}images/experience/arspaceMedia1.jpg`
const arspaceMedia2 = `${process.env.resourceUrl}images/experience/arspaceMedia2.jpg`

export {
    aircastSetup1,
    aircastSetup2,
    arspaceMedia1,
    arspaceMedia2,
 
    desktopPic,
    arspaceGlass,
    nosmooth,
    smooth,
    drm_1,
    drm_2,
    drm_3,
    drm_4,
    drm_5,
    drm_6,
    drm_7,
    drm_8,
    projector, vr,xrealair,
    // nowire, 
    // haswire,
    odofGif,
    threeTypeAnchor,
    threeTypeSmooth,
    threeTypeSide,
   
    upgradeBg,
    lightBanner,
    s1,
    s2,
    s3,
    s4,
    s5,
    s6,
    s7,
    m1,
    m2,
    m3,
    m4,
    m5,
    m6,
    m7,
    m8,
    v1,
    v2,
    v3,
    v4,
    headerBg, 
    compatibilityada, 
    compatibilityandroid, 
    compatibilityav, 
    compatibilitydp, 
    compatibilityhdmi, 
    compatibilitymac, 
    checkMac, 

    windowLogo, 
    macLogo,
    detectTrue, 
    detectFalse, 
    nebulaIcon, 
    nebulaMacIcon,
    googlePlay,
    windowIcon,
    macIcon,
    youtubeIcon,
    teleportIcon,
  
   
    
}